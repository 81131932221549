import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import Loading from '../Loading';
import InputWithLabel from '../InputWithLabel';
import { RootState } from '../../redux/store';
import { getQuestion } from '../../redux/slices/showQuestionSlice';
import { editVal } from '../../redux/slices/EditQuestionSlice';
import { Block, ErrorMessage } from '../styled';
import * as PreviewComponents from '../../pages/Assessments/PreviewComponents';
import PreviewMatchList from 'components/PreviewQuestions/PreviewMatchList/PreviewMatchList';
import PreviewClassification from 'components/PreviewQuestions/PreviewClassification/PreviewClassification';
import PreviewSortList from 'components/PreviewQuestions/PreviewSortList/PreviewSortList';
import PreviewOrderList from 'components/PreviewQuestions/PreviewOrderList/PreviewOrderList';
import { ChartQuestionTypes } from 'pages/AuthorQuestion/Charts/SubChildren/components/chartquestion.constants';

const EditBasicDetails: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { dropSubjects } = useSelector((state: RootState) => state.subjects);
  const { dropGrades } = useSelector((state: RootState) => state.grades);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subQuestionId, setSubQuestionId] = useState();

  const { name, subject } = useSelector(
      (state: RootState) => state?.showQuestion?.currentQuestion ?? {}
  );
  const { currentQuestion } = useSelector((state: RootState) => state.showQuestion);
  const editQuestion = useSelector((state: RootState) => state.editQuestion);

  const { register, formState: { errors } } = useForm();

  useEffect(() => {
    dispatch(getQuestion(id) as any);
  }, [id]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000);
  }, []);

  const handleDropdownChange = (
      type: 'subject' | 'grade',
      value: string
  ) => {
    if (type === 'subject') {
      const selectedSubject = dropSubjects?.find((s) => s.id === value);
      if (selectedSubject) {
        dispatch(editVal({ key: 'subject', val: selectedSubject }));
        dispatch(editVal({ key: 'grade', val: null }));
      }
    } else if (type === 'grade' && editQuestion?.subject?.grades) {
      const selectedGrade = editQuestion?.subject?.grades.find((g) => g.id === value);
      if (selectedGrade) {
        dispatch(editVal({ key: 'grade', val: selectedGrade }));
      }
    }
  };

  const renderQuestionPreview = (question: any) => {
    const PreviewMap: Record<string, React.FC<any>> = {
      mcs: PreviewComponents.PreviewMultipleStandard,
      mcmr: PreviewComponents.PreviewMultipleResponses,
      taf: PreviewComponents.PreviewTrueFalse,
      mcbl: PreviewComponents.PreviewBlockLayout,

      cms: PreviewComponents.PreviewMatrixStandard,
      cmi: PreviewComponents.PreviewMatrixStandard,
      cml: PreviewComponents.PreviewMatrixStandard,
      cmsnl: PreviewComponents.PreviewMatrixStandard,

      cwdad: PreviewComponents.PreviewClozeDragDrop,
      cwdd: PreviewComponents.PreviewClozeDropDown,
      cwt: PreviewComponents.PreviewClozeWithText,
      math: PreviewComponents.PreviewMath,
      clozmat: PreviewComponents.PreviewClozeMath,
      clozmwIm: PreviewComponents.PreviewClozeImageMath,
      liwdd: PreviewComponents.PreviewImageDragDrop,
      liwt: PreviewComponents.PreviewImageWithText,
      liwddo: PreviewComponents.PreviewImageDropDown,
      machli: PreviewMatchList,
      class: PreviewClassification,
      mewrt: PreviewComponents.PreviewMathEssayWithText,
      ewrt: PreviewComponents.PreviewWrittenRecordedMathEssayRT,
      st: PreviewComponents.PreviewShortText,
      liwm: PreviewComponents.PreviewLabelmageMath,
      ewpt: PreviewComponents.PreviewWrittenRecordedMathEssayPT,
      ordlis: PreviewOrderList,
      ar: PreviewComponents.PreviewAudioRecorder,
      sortlis: PreviewSortList,
      fileup: PreviewComponents.PreviewOtherFileUpload,
      graph: PreviewComponents.PreviewGraphingUnit,
      shad: PreviewComponents.PreviewShading,
      imup: PreviewComponents.PreviewImageAnnotation,
      drawing: PreviewComponents.PreviewDrawing,
      [ChartQuestionTypes.BARCHART]: PreviewComponents.PreviewSimpleChart,
      [ChartQuestionTypes.HISTOGRAM]: PreviewComponents.PreviewHistogram,
      [ChartQuestionTypes.LINECHART]: PreviewComponents.PreviewLineChart,
      [ChartQuestionTypes.DOTPLOT]: PreviewComponents.PreviewDotPlot,
      [ChartQuestionTypes.LINEPLOT] : PreviewComponents.PreviewLinePlot,
      rating: PreviewComponents.PreviewRating,
      grid: PreviewComponents.PreviewGridded,

    };

    const Component = PreviewMap[question?.type];
    return Component ? (
        <Component
            key={question?.id}
            currentQuestion={question}
            showAnswer={false}
            editMode={true}
            parentMode="question"
        />
    ) : (
        <div>{`Preview not available for type: ${question.type}`}</div>
    );
  };

  return (
      <>
        {isLoading ? (
            <div style={{ marginTop: '-150px' }}>
              <Loading />
            </div>
        ) : (
            <>
              <h2 className="font-bold mb-4">Question: {name || ''}</h2>
              <div className="flex gap-5 mt-5 pb-4">
                <Block>
                  <InputWithLabel
                      {...register('name', { required: true })}
                      label="Question Name*"
                      placeholder="Enter Question Name*"
                      error={errors?.name}
                      onChange={(e) => dispatch(editVal({ key: 'name', val: e.target.value }))}
                      value={editQuestion?.name || ''}
                      width="350px"
                  />
                </Block>

                <Block>
                  <div className="align-center justify-center mt-1">
                    <p className="font-semibold">Subjects*</p>
                    <select
                        onChange={(e) => handleDropdownChange('subject', e.target.value)}
                        value={editQuestion?.subject?.id || subject?.id || ''}
                        className="h-10 mt-1 rounded-md p-2 border border-solid"
                    >
                      <option disabled>Select Subject</option>
                      {dropSubjects?.map((s) => (
                          <option key={s?.id} value={s?.id}>
                            {s?.label}
                          </option>
                      ))}
                    </select>
                    {error && <ErrorMessage>Subject is required.</ErrorMessage>}
                  </div>
                </Block>

                <Block>
                  <div className="align-center justify-center mt-1">
                    <p className="font-semibold">Grades</p>
                    <select
                        onChange={(e) => handleDropdownChange('grade', e.target.value)}
                        value={editQuestion?.grade?.id || ''}
                        className="h-10 mt-1 rounded-md p-2 border border-solid"
                    >
                      <option value="">Select Grade</option>
                      {editQuestion?.subject?.grades?.map((g) => (
                          <option key={g?.id} value={g?.id}>
                            {g?.label || g?.name}
                          </option>
                      ))}
                    </select>
                  </div>
                </Block>
              </div>

              {currentQuestion?.sub_questions?.map(renderQuestionPreview)}

              {isModalOpen && (
                  <Modal
                      setIsModalOpen={setIsModalOpen}
                      id={subQuestionId}
                      isModalOpen={isModalOpen}
                      api="api/subQuestion"
                      type="Sub Question"
                      method={async () => dispatch(getQuestion(id) as any)}
                  />
              )}
            </>
        )}
      </>
  );
};

export default EditBasicDetails;

import React, { useState } from 'react';

import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
// import DrawingCanvas from 'components/Drawing/DrawingCanvas.component';
import CanvasGeogebra from 'components/Drawing/DrawingCanvasGeogebra.component';

const PreviewDrawing = ({
  currentQuestion,
  showAnswer,
  setShowAnswers = undefined,
  parentMode = 'question',
  editMode = false,
}) => {
  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const more_options = currentQuestion?.more_options;

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(
        setTypeQuestionOpenInEditMode({
          type: currentQuestion?.type,
          id: currentQuestion?.id,
        })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };
  
  const drawingTools = currentQuestion?.template_response?.drawing_tools || [];
  const layout = currentQuestion?.more_options ? currentQuestion?.more_options?.layout : null;

  return (
    <PreviewWrapper
          currentQuestion={currentQuestion}
          showAnswer={showAnswer ?? localShowAnswer}
          setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
          parentMode={parentMode}
          editMode={editMode}
          handleGoToEdit={handleGoToEditClick}
          handleBackToEdit={handleBackToEditClick}    
      > 

      <div>
        <CanvasGeogebra 
          drawingTools={drawingTools}
          layout ={layout}
        />
      </div>
    </PreviewWrapper>
  );
};

export default PreviewDrawing;

import { useEffect, useState } from 'react';
import { QuestionContent } from './styled';
import Button from '../../../components/Button';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from 'react-router-dom';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import QuestiontypeModal from 'components/QuestiontypeModal';
import { ChartQuestionTypes } from 'pages/AuthorQuestion/Charts/SubChildren/components/chartquestion.constants';
import DotPlotGraph from 'pages/AuthorQuestion/Charts/SubChildren/components/dotPlotGraph.component';
import uuid from 'react-uuid';
import AddButtonForCharts from 'pages/AuthorQuestion/Charts/SubChildren/components/addButtonForChart.component';

const PreviewDotPlot = ({
  currentQuestion,
  showAnswer = false,
  setShowAnswers = null,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const validResponse = currentQuestion?.correct_answer?.valid_response;
  const more_options = currentQuestion?.more_options;
  const layout = more_options?.layout;
  const initialResponse = {
    value: currentQuestion.template_response.chartData,
    score: 0,
  }
  const [userResponse, setUserResponse] = useState(null);

  useEffect(() => {
    if(!userResponse) {
        setUserResponse(initialResponse);
    }
  }, [])

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode({type: ChartQuestionTypes.DOTPLOT, id: currentQuestion?.id}));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleAddNewPoint = () => {
    const newlyAddedPoints = userResponse.value.filter((point) => point.id.includes('new'));
    const newPointName = layout?.new_point_name ? layout?.new_point_name : 'New Point'

    setUserResponse({...userResponse, value: [...userResponse.value, {
      id: `new ${uuid()}`, 
      x: `${newPointName} ${newlyAddedPoints.length + 1}`, 
      y: "0"
    }]});
  }

  return (
    <QuestionContent parentMode={parentMode === 'question'}>
      {
        more_options?.extras?.instructor_stimulus && !editMode &&
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {more_options?.extras?.instructor_stimulus}
        </div>
      }
      <div className="flex flex-wrap gap-5 justify-between mb-5">
        <div className="w-full lg:w-[70%]">
          <div className="bg-light-accent flex p-5 gap-3 rounded-lg">
            <p className="font-bold text-sm">Question :&nbsp;</p>
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${currentQuestion?.question}`,
              }}
            />
          </div>
        </div>

        <div className="flex gap-4">
          {editMode ? (
            <>
              <Button
                margin
                title={'Go to edit'}
                onClick={handleEditClick}
              />

              <Button
                title={'Delete'}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />
              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              <Button
                margin
                title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                onClick={() => {
                  if (setShowAnswers) setShowAnswers(!showAnswer);
                }}
              />

              <Button
                title={'Back to edit'}
                onClick={() => {
                  // @ts-ignore
                  dispatch(setClosePreview());
                }}
              />

              <Button
                title={'Reset'}
                onClick={() => {
                    setUserResponse(initialResponse);
                    if (showAnswer) setShowAnswers(!showAnswer);
                }}
              />
            </>
          )}
        </div>
      </div>

     {userResponse && 
      <div>
      {
        layout && !!layout?.add_point &&
        <AddButtonForCharts 
          handleAddNewPoint={handleAddNewPoint}
        />
      }
        <DotPlotGraph
          activeTab={ showAnswer ? validResponse : userResponse}
          questionData={currentQuestion}
          disabled = {!!editMode}
          handleBarData={(data) => {
            if(!editMode) {
              setUserResponse({
                value: data,
                score: 0
              })
            }
          }}
        />
      </div>
     }
    </QuestionContent>
  );
};

export default PreviewDotPlot;

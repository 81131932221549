import React from 'react';
import styled from 'styled-components';
import { FontSize, StyledInputProps } from './types';

const InputWrapper = styled.div`
  display: flex;
  gap: 2px;
  margin-bottom: 8px;
`;

const Input = styled.input<StyledInputProps>`
  width: 60px;
  height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: ${props => props.$fontSize === 'small' ? '14px' : props.$fontSize === 'large' ? '20px' : '16px'};

  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
`;

interface GriddedInputProps {
    columns: number;
    values: string[];
    fontSize: FontSize;
    onChange: (values: string[]) => void;
    disabled?: boolean;
    options: {
        range?: { min: number; max: number };
        plusMinus?: boolean;
        fractionSlash?: boolean;
        fixedDecimal?: boolean;
        floatingDecimal?: boolean;
    };
}

export const GriddedInput: React.FC<GriddedInputProps> = ({
                                                              columns,
                                                              values,
                                                              fontSize,
                                                              onChange,
                                                              disabled,
                                                              options
                                                          }) => {
    const handleInputChange = (index: number, value: string) => {
        const newValues = [...values];
        newValues[index] = value;
        onChange(newValues);
    };



    return (
        <InputWrapper>
            {Array.from({ length: options?.plusMinus ? columns : columns - 1 }).map((_, index) => (
                <Input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={values[index] || ''}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    disabled={disabled}
                    $fontSize={fontSize}
                    aria-label={`Column ${index + 1} input`}
                />
            ))}
        </InputWrapper>
    );
};

import React, { useState } from 'react';
import { QuestionContent } from './styled';
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from 'components/styled';
import ButtonLink from 'components/ButtonLink';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import { setClosePreview } from 'redux/slices/PreviewSlice';
import QuestiontypeModal from 'components/QuestiontypeModal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomJoditEditor from './../../../components/CustomJoditEditor/index';

interface PreviewMathEssayProps {
  currentQuestion: {
    id: number;
    more_options: {
      ui_style: {
        type: "",
        showHints: false,
        keyboard_below_response_area: false,
        horizontal_layout: false,
        show_word_count: false
    },
    },
    question: string;
    options: {
      content: string;
      formatting_options: string[];
      max_length: number;
      show_word_limit: string;
    };
    type: string;
  };
  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewMathEssayWithText: React.FC<PreviewMathEssayProps> = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editorStyle = {
    height: '150px', // Set the desired height here
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [content, setContent] = useState<string>(
    currentQuestion?.options.content || ""
  );
  const [isOverLimit, setIsOverLimit] = useState(false);

  const maxWords = currentQuestion.options.max_length;
  const url = window.location.href;
  const regex = /\/edit-question\/([^\/]+)$/;
  const match = url.match(regex);
  const isID = match ? match[1] : null;

  const config = {
    toolbar: true,
    readonly:isID !== null,
   // buttons: currentQuestion?.options?.formatting_options,
   buttons:['bold', 'italic', 'underline']
  };
  
  console.log(config, " Editor config");


  const handleEditorBlur = (newContent: string) => {
    const text = newContent.trim();
    const words = text.split(/\s+/).filter((word) => word !== '');




    if (words.length > maxWords) {
      setIsOverLimit(true);
    } else {
      setIsOverLimit(false);
    }
    setContent(newContent);
  };
  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

 
  console.log('currentQuestion', currentQuestion);
  const wordCount =
    content.trim() === '<p><br></p>'
      ? 0
      : content
          .trim()
          .split(/\s+/)
          .filter((word) => word !== '').length;

  return (
    <QuestionContent parentMode={parentMode === 'question'}>
      <FullWidthContainer>
        <LeftContent>
          <BlockTitle
            dangerouslySetInnerHTML={{
              __html: `Question: ${currentQuestion?.question}`,
            }}
          />
        </LeftContent>
        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={'Go to edit'}
                onClick={() => handleEditClick()}
              />
              <ButtonLink
                margin
                title={'Delete'}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />
              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id as any}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              {/* <Button
                variant="outlined"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (setShowAnswers) {
                    setShowAnswers(!showAnswer);
                  } else {
                    setLocalShowAnswer(!localShowAnswer);
                  }
                }}
              >
                {showAnswer || localShowAnswer ? "Hide Answer" : "Show Answer"}
              </Button> */}

              <button
                type="button"
                onClick={() => {
                  dispatch(setClosePreview());
                }}
                style={{
                  width: '150px',
                  height: '40px',
                  fontWeight: 600,
                  color: '#FFF',
                  borderRadius: '8px',
                  backgroundColor: '#FA9B31',
                }}
              >
                Back to edit
              </button>
            </>
          )}
        </RightContent>
      </FullWidthContainer>

      <div>
        <div className={isOverLimit ? 'editor-over-limit' : 'editor'}>
          {/* <CKEditor
            editor={ClassicEditor}
            data={content}
            config={config}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleEditorBlur(data);
            }}
          /> */}

<CustomJoditEditor
        label=""
        value={content}
        allconfig={true}
        onBlur={handleEditorBlur}
      //  userconfig={config}
      //  includeOptionalButton={true} 

      />
        </div>
        {/* <div
          className="flex gap-1 mt-2"
          style={isOverLimit ? { color: 'red' } : {}}
        >
          {currentQuestion?.options?.show_word_limit === "off" ? (
            ""
          ) : (
            <p>Word Limit: {maxWords}</p>
          )}
          {currentQuestion?.more_options?.ui_style?.show_word_count && (
            <p>
              {currentQuestion?.options?.show_word_limit === "off"
                ? "Word(s) "
                : "/ "}
              {wordCount}
            </p>
          )}
        </div> */}
         <div
          className="flex gap-1 mt-2"
          style={isOverLimit ? { color: "red" } : {}}
        >
          {currentQuestion?.options?.show_word_limit === "alwaysVisible" || currentQuestion?.options?.show_word_limit === "" && (
              <p>{wordCount} / {maxWords} Word Limit</p>
          )}
          { (currentQuestion?.options?.show_word_limit === "onLimit" && (wordCount < maxWords || wordCount == 0)) && (
              <p>{wordCount} Word(s)</p>
          )}
          {(currentQuestion?.options?.show_word_limit === "off" && (wordCount > maxWords || wordCount == 0)) && (
              <p>{wordCount} Word(s)</p>
          )}
          {(currentQuestion?.options?.show_word_limit === "onLimit" && wordCount > maxWords && wordCount !== 0) && (
              <p>{wordCount} / {maxWords} Word Limit</p>
          )}
        </div>
      </div>

      {isModalOpen && (
        <QuestiontypeModal
          setIsModalOpen={setIsModalOpen}
          id={currentQuestion?.id as any}
          isModalOpen={isModalOpen}
        />
      )}
    </QuestionContent>
  );
};

export default PreviewMathEssayWithText;
import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Stage, Layer, Image as KonvaImage, Line, Circle, Group, Text } from 'react-konva';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { CanvasWrapper, CanvasContainer } from './CanvasStyles';
import { UserResponse, ImageAnnotation, Point, Polygon } from './types';
import { isPointInPolygon, getCircleIndicatorPosition } from './geometry';
import Button from './Button';
import ResponseBox from './ResponseBox';
import { getLabelByStemNumeration } from 'utils/generalUtils';


interface CanvasPreviewProps {
    currentQuestion?: any;
    showAnswer?: boolean;
    parentMode?: string;
    editMode?: boolean;
}

interface ValidatedResponse extends UserResponse {
    isCorrect: boolean | null;
}

const CanvasPreview: React.FC<CanvasPreviewProps> = ({
                                                         currentQuestion,
                                                         showAnswer = false,
                                                         parentMode ,
                                                         editMode = false
                                                     }) => {
    const questionType = "imup";
    const selectorImageAnnotation = useSelector(
        (state: RootState) => state?.question?.subQuestions?.[questionType]
    );
console.log("selectorImageAnnotation",selectorImageAnnotation)
    // Use currentQuestion in edit mode, otherwise use selector data
    const imageAnnotation: ImageAnnotation | undefined = useMemo(() => {
        return editMode ? currentQuestion : selectorImageAnnotation;
    }, [editMode, currentQuestion, selectorImageAnnotation]);

    // @ts-ignore
    const imageRef = useRef<KonvaImage>(null);
    const stageRef = useRef(null);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [userResponses, setUserResponses] = useState<ValidatedResponse[]>([]);
    const [selectedResponseIndex, setSelectedResponseIndex] = useState<number | null>(null);

    const polygons = useMemo(() =>
            imageAnnotation?.correct_answer?.valid_response?.value || [],
        [imageAnnotation]
    );

    // Load image only when source changes
    useEffect(() => {
        if (imageAnnotation?.image?.source) {
            const img = new window.Image();
            img.src = imageAnnotation.image.source;
            img.onload = () => setImage(img);
        }
    }, [imageAnnotation?.image?.source]);

    const validateResponse = useCallback((response: ValidatedResponse) => {
        if (!response.answer.trim()) return false;
    
        const circlePosition = getCircleIndicatorPosition(response);
    
        // Check the case sensitivity setting from more options (this could be passed in or fetched from props or context)
        const caseSensitive = currentQuestion?.more_options?.scoring?.case_sensitive ?? false;
    
        for (const polygon of polygons) {
            const points = polygon.points.map(p => ({ x: p.x, y: p.y }));
    
            if (isPointInPolygon(circlePosition, points)) {
                const responseAnswer = response.answer.trim();
                const polygonLabel = polygon.label.trim();    
                if (caseSensitive) {
                    return responseAnswer === polygonLabel;
                } else {
                    return responseAnswer.toLowerCase() === polygonLabel.toLowerCase();
                }
            }
        }
    
        return false;
    }, [polygons, currentQuestion]);
    

    const updateResponseValidation = useCallback(() => {
        if (!showAnswer) {
            setUserResponses(prev => prev.map(response => ({ ...response, isCorrect: null })));
            return;
        }

        setUserResponses(prev =>
            prev.map(response => ({
                ...response,
                isCorrect: validateResponse(response)
            }))
        );
    }, [showAnswer, validateResponse]);

    // Update validation when showAnswer changes
    useEffect(() => {
        updateResponseValidation();
    }, [showAnswer, updateResponseValidation]);

    const addNewResponse = useCallback(() => {
        const stageWidth = imageAnnotation?.image?.width || 800;
        const newResponse: ValidatedResponse = {
            id: `response-${Date.now()}`,
            position: {
                x: (stageWidth / 2) - 100,
                y: 20,
            },
            answer: "",
            width: 200,
            height: 40,
            isCorrect: null
        };

        setUserResponses(prev => [...prev, newResponse]);
        setSelectedResponseIndex(prev => (prev === null ? prev : prev + 1));
    }, [imageAnnotation?.image?.width]);

    const handleDragMove = useCallback((index: number, e: any) => {
        const { x, y } = e.target.position();
        setUserResponses(prev => {
            const newResponses = [...prev];
            newResponses[index] = {
                ...newResponses[index],
                position: { x, y },
            };
            return newResponses;
        });
    }, []);

    // Debounced validation after drag
    useEffect(() => {
        if (showAnswer) {
            const timeoutId = setTimeout(updateResponseValidation, 100);
            return () => clearTimeout(timeoutId);
        }
    }, [showAnswer, userResponses, updateResponseValidation]);

    const handleGroupClick = useCallback((index: number, e: any) => {
        e.cancelBubble = true;
        setSelectedResponseIndex(index);
    }, []);

    const updateResponse = useCallback((index: number, answer: string) => {
        setUserResponses(prev => {
            const newResponses = [...prev];
            newResponses[index] = {
                ...newResponses[index],
                answer,
            };
            return newResponses;
        });
    }, []);

    const deleteResponse = useCallback((index: number) => {
        setUserResponses(prev => prev.filter((_, i) => i !== index));
        setSelectedResponseIndex(null);
    }, []);

    const clearResponses = useCallback(() => {
        setUserResponses([]);
        setSelectedResponseIndex(null);
    }, []);

    const handleStageMouseDown = useCallback((e: any) => {
        if (e.target === e.target.getStage()) {
            setSelectedResponseIndex(null);
        }
    }, []);

    const handleDeleteSelected = useCallback(() => {
        if (selectedResponseIndex !== null) {
            deleteResponse(selectedResponseIndex);
        }
    }, [deleteResponse, selectedResponseIndex]);

    const handleDeselect = useCallback(() => {
        setSelectedResponseIndex(null);
    }, []);
    return (
        <CanvasWrapper>
            <CanvasContainer>
                <Stage
                    ref={stageRef}
                    width={imageAnnotation?.image?.width || 800}
                    height={imageAnnotation?.image?.height || 600}
                    onMouseDown={handleStageMouseDown}
                >
                    <Layer>
                        {image && (
                            <KonvaImage
                                ref={imageRef}
                                image={image}
                                x={0}
                                y={0}
                                width={imageAnnotation?.image?.width}
                                height={imageAnnotation?.image?.height}
                                alt={imageAnnotation?.image?.imgAlt}
                            />
                        )}

                        {showAnswer && polygons.map((polygon, index) => (
                            <Group key={`polygon-${index}`} draggable={false}>
                                <Line
                                    points={polygon.points.flatMap((point) => [point.x, point.y])}
                                    stroke="blue"
                                    strokeWidth={2}
                                    closed
                                    fill="rgba(0,0,255,0.3)"
                                />
                                {polygon.points.map((point, i) => (
                                    <Circle
                                        key={`point-${i}`}
                                        x={point.x}
                                        y={point.y}
                                        radius={5}
                                        fill="red"
                                    />
                                ))}
                                {polygon.label && (
                                    <Text
                                        text= {getLabelByStemNumeration(currentQuestion.more_options, index)}
                                        x={polygon.points[0]?.x || 0}
                                        y={polygon.points[0]?.y - 20 || 0}
                                        fontSize={16}
                                        fill="black"
                                        padding={4}
                                    />
                                )}
                            </Group>
                        ))}
                
                        {userResponses.map((response, index) => (
                            <ResponseBox
                                key={response.id}
                                response={response}
                                index={index}
                                isSelected={selectedResponseIndex === index}
                                isCorrect={response.isCorrect}
                                MoreOptions={currentQuestion.more_options}
                                onDragMove={handleDragMove}
                                onClick={handleGroupClick}
                                onUpdateResponse={updateResponse}
                                onDelete={deleteResponse}
                                onDeselect={handleDeselect}
                               
                               
                            />
                        ))}
                    </Layer>
                </Stage>

                <div className="mt-4 space-y-4">
                    <div className="flex justify-center gap-4">
                        <Button name="Add Response" onClick={addNewResponse} />
                        <Button name="Clear All" onClick={clearResponses} />
                        {selectedResponseIndex !== null && (
                            <Button name="Delete Selected" onClick={handleDeleteSelected} />
                        )}
                    </div>
                </div>
            </CanvasContainer>
        </CanvasWrapper>
    );
};

export default React.memo(CanvasPreview);

// Utility functions for geometric calculations
export function isPointInPolygon(point: { x: number; y: number }, polygon: { x: number; y: number }[]): boolean {
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x, yi = polygon[i].y;
        const xj = polygon[j].x, yj = polygon[j].y;

        const intersect = ((yi > point.y) !== (yj > point.y))
            && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
}

export function getCircleIndicatorPosition(response: { position: { x: number; y: number }; height: number }) {
    return {
        x: response.position.x - 15, // Circle is positioned 15px to the left of the response box
        y: response.position.y + response.height / 2 // Circle is vertically centered
    };
}

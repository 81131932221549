import Geogebra from 'react-geogebra'
import uuid from 'react-uuid';

const CanvasGeogebra = ({drawingTools, layout} : {drawingTools: string[], layout: any}) => {

    const getCustomeToolBar = () => {
        let mappedTools = '0 1 |39 40 ';
        const mappedToolsObj = {
            Scribble: '61 |62 73 ', 
            Line: '|15 45 ', 
            Compass: '|10 20 24 34 53 55 ', 
            Eraser: '|6 ', 
            Text: '|17 2040 ',
        }
        Object.keys(mappedToolsObj).forEach((key: string) => {
            if(drawingTools.includes(key)){
                mappedTools += mappedToolsObj[`${key}`];
            }
        })
        return mappedTools;
    }

    const width = layout && !isNaN(Number(layout?.canavas_width)) ? Number(layout?.canavas_width) : 800;
    const height = layout && !isNaN(Number(layout?.canavas_height)) ? Number(layout?.canavas_height) : 600;
    const showGrid = layout && layout?.show_grid ? Boolean(layout?.show_grid) : false;

    const handleReset = () => {
        let geogebraApp : any = window.document.getElementById(uuid());
        if (geogebraApp) {
            // Trigger reset method on Geogebra app instance
            geogebraApp?.reset();
        }
    };


    return(
        <Geogebra
            width={width}
            height={height}
            showMenuBar={false}
            showToolBar={true}
            showAlgebraInput={false}
            id={uuid()}
            appName='Obeassess'
            appletOnLoad={() => {}}
            LoadComponent={() => <h1>Loading</h1>}
            reloadOnPropChange={true}
            // customToolBar='1 17 6 2 10 15 20 24 34 39 40 53 55 61 62 73 2040 26'
            customToolBar={getCustomeToolBar()}
            showStartTooltip={false}
            // showResetIcon={true}
            showResetIcon = {drawingTools.includes('Clear')}
            showSuggestionButtons = {false}
            showAnimationButton = {false}
            showToolBarHelp={false}
            showZoomButtons = {false}
            errorDialogsActive={false}
            // useBrowserForJS = {true}
            enableFileFeatures={false}
            // @ts-ignore
            enableUndoRedo = {drawingTools.includes('Undo/Redo')}
            perspective={showGrid ? 'G' : '2'}
            transparentGraphics = {true}
        />
    )
}

export default CanvasGeogebra;

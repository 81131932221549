import React from 'react';
import styled from 'styled-components';
import { GriddedInput } from './GriddedInput';
import { GriddedTable } from './GriddedTable';
import type { GriddedProps } from './types';
import {useSelector} from "react-redux";
import {RootState} from "redux/store";
import {useGridConfig} from "./useGridConfig";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GriddedResponse: React.FC<GriddedProps> = ({
                                                     value,
                                                     onChange,
                                                     disabled
                                                 }) => {
    const questionType = useSelector((state: RootState) => state.editMode.questionType) || 'grid';
    const question = useSelector((state: RootState) =>
        state.editMode.isEditMode
            ? state.editSubQuestion.subQuestions[questionType]
            : state.question.subQuestions[questionType]
    );
    console.log('**************************************', question)
    const config = useGridConfig(question);

    const handleInputChange = (newValues: string[]) => {
        onChange({
            ...value,
            values: newValues
        });
    };

    const handleTableChange = (column: number, inputValue: string) => {
        const newValues = [...(value?.values || [])];
        newValues[column] = inputValue;
        onChange({
            ...value,
            values: newValues
        });
    };

    return (
        <Container>
            <GriddedInput
                columns={config?.columns}
                values={value?.values || []}
                fontSize={config?.fontSize}
                options={config?.features}
                onChange={handleInputChange}
                disabled={disabled}
            />
            <GriddedTable
                columns={config?.columns}
                range={config?.range}
                options={config?.features}
                fontSize={config?.fontSize}
                onChange={handleTableChange}
                disabled={disabled}
                showPlusRow={config?.features?.plusMinus}
                showMinusRow={config?.features?.plusMinus}
                selectedValues={value?.values || []}
            />
        </Container>
    );
};

export default GriddedResponse;

import { MouseEvent, MouseEventHandler } from "react";

interface IModalEditBarValue {
    inputValue: string; 
    showModal: boolean; 
    setInputValue: Function;
    handleSave: (e: any) => void;
    handleCancel: (e: any) => void;
}


const ModalEditBarValue = ({inputValue, showModal, setInputValue, handleCancel, handleSave} : IModalEditBarValue) => {
    return showModal ? (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            width: '300px',
            borderRadius: '8px',
          }}
        >
            <input 
                type="text"
                style={{
                    width: '100%',
                    padding: '8px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                }}
                value={inputValue}
                onChange={e => {
                    setInputValue(e.target.value);
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <button
                    onClick={handleSave}
                    style={{
                        backgroundColor: '#3182CE',
                        padding: '8px 16px',
                        border: 'none',
                        borderRadius: '4px',
                        color: 'white',
                        cursor: 'pointer',
                        flex:1,
                        margin: 4
                      }}
                >
                    Ok
                </button>

                <button
                    onClick={handleCancel}
                    style={{
                        backgroundColor: '#ccc',
                        padding: '8px 16px',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        flex: 1,
                        margin: 4,
                      }}
                >
                    Cancel
                </button>

            </div>
        </div>
    ) : null
}

export default ModalEditBarValue;
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDisplayPreview } from '../../redux/slices/PreviewSlice';
import { BiEdit } from 'react-icons/bi';

import { BsFillQuestionCircleFill, BsFillStarFill } from 'react-icons/bs';
import {
  FaPlus,
  FaChevronLeft,
  FaUserPlus,
  FaFileSignature,
} from 'react-icons/fa';

import {
  AiFillTag,
  AiOutlineUpload,
  AiOutlinePlus,
  AiFillEye,
  AiFillSetting,
} from 'react-icons/ai';
import { FiSave } from 'react-icons/fi';

import { useNavigate } from 'react-router-dom';

import {
  StyledButton,
  StyledButtonContainer,
  StyledDashboardHeader,
  StyledHeading,
  StyledSubTitle,
  Titles,
} from './styled';
import { SiUplabs } from 'react-icons/all';
import HelpModal from '../HelpModal';
import { saveCopySubQuestion } from '../../redux/slices/QuestionSlice';
import { RootState } from '../../redux/store';

import Font from 'react-font';
import { ROLE } from 'utils/roles';

interface SubHeaderProps {
  title?: string;
  subTitle?: string;
  showButtons?: string[];
  url?: string;
  method?: any;
  data?: any;
  backPath?: string;
  disableSaveBtn?: boolean;
  submitBtnRef?: React.MutableRefObject<any>;
}

const SubHeader: React.FC<SubHeaderProps> = ({
  title,
  subTitle,
  showButtons,
  url,
  method,
  data,
  backPath,
  disableSaveBtn,
  submitBtnRef,
}) => {
  const dispatch = useDispatch();
  const { saveDraftQuestion } = useSelector(
    (state: RootState) => state?.editMode
  );
  const { user } = useSelector((state: RootState) => state.authentication);

  // const ref = useRef(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { clientX, clientY } = event;
    setModalPosition({ top: clientY, left: clientX });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleItemClick = (path: string) => {
    navigate(path);
  };

  return (
    <StyledDashboardHeader>
      <Titles>
        <Font family="Inter" weight={500}>
          <StyledHeading
            onClick={() => {
              if (title === 'Dashboard') {
                let dashboardPath = [ROLE.Manager].includes(user?.roles[0])
                  ? '/client-dashboard'
                  : '/admin-dashboard';
                navigate(dashboardPath);
              } else if (backPath) navigate(backPath);
              else navigate(-1);
            }}
          >
            <FaChevronLeft
              style={{ color: '#000', alignSelf: 'center', marginRight: '5px' }}
              // className={`text-3xl -right-3 top-20 border  cursor-pointer shadow-2xl`}
            />
            {title}
          </StyledHeading>
        </Font>

        <Font family="Inter">
          <StyledSubTitle>{subTitle}</StyledSubTitle>
        </Font>
      </Titles>

      <StyledButtonContainer className="flex-wrap">
        {showButtons?.includes('subject') && (
          <StyledButton onClick={() => handleItemClick('/create-subject')}>
            <FaPlus /> Create Subject
          </StyledButton>
        )}
        {showButtons?.includes('grade') && (
          <StyledButton onClick={() => handleItemClick('/create-grade')}>
            <BsFillStarFill /> Create Grade
          </StyledButton>
        )}
        {showButtons?.includes('user') && (
          <StyledButton onClick={() => handleItemClick('/create-user')}>
            <FaUserPlus /> Create User
          </StyledButton>
        )}
        {showButtons?.includes('upload') && (
          <StyledButton onClick={() => navigate(url)}>
            <AiOutlineUpload /> Bulk Upload
          </StyledButton>
        )}
        {showButtons?.includes('questions') && (
          <StyledButton onClick={() => handleItemClick('/add-question-bank')}>
            <FaFileSignature /> Create Questions
          </StyledButton>
        )}
        {showButtons?.includes('assessment') && (
          <StyledButton onClick={() => handleItemClick('/new-assessment')}>
            <BsFillStarFill /> Create Assessment
          </StyledButton>
        )}
        {showButtons?.includes('preview') && (
          <StyledButton
            onClick={() => {
              // @ts-ignore
              dispatch(saveCopySubQuestion({ type: saveDraftQuestion }));
              // @ts-ignore
              dispatch(setDisplayPreview());
            }}
          >
            <AiFillEye /> Preview
          </StyledButton>
        )}
        {showButtons?.includes('setting') && (
          <StyledButton onClick={() => handleItemClick('/new-assessment')}>
            <AiFillSetting /> Setting
          </StyledButton>
        )}
        {showButtons?.includes('tags') && (
          <StyledButton onClick={() => handleItemClick('/create-tag-type')}>
            <AiFillTag /> Create Tags
          </StyledButton>
        )}
        {showButtons?.includes('tagsTypes') && (
          <StyledButton onClick={() => handleItemClick('/create-tag-type')}>
            <AiFillTag /> Create Tag Type
          </StyledButton>
        )}
        {showButtons?.includes('tagsHierarchy') && (
          <StyledButton
            onClick={() => handleItemClick('/create-tag-hierarchy')}
          >
            <AiFillTag /> Create Tag Hierarchy
          </StyledButton>
        )}

        {showButtons?.includes('save') && (
          <StyledButton
            disabled={disableSaveBtn}
            onClick={() => method(data)}
          >
            <FiSave /> Save
          </StyledButton>
        )}

        {submitBtnRef && showButtons?.includes('create-question') && (
          <StyledButton
            disabled={!!disableSaveBtn}
            onClick={() => submitBtnRef.current.click()}
          >
            <FiSave /> save
          </StyledButton>
        )}

        {/*        {showButtons?.includes('new') && (
          <StyledButton onClick={() => () => method(data)}>
            <AiOutlinePlus /> Save & Add New
          </StyledButton>
        )}*/}
        {showButtons?.includes('help') && (
          <StyledButton onClick={handleOpenModal}>
            <BsFillQuestionCircleFill /> Help
          </StyledButton>
        )}
      </StyledButtonContainer>
      <HelpModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        position={modalPosition}
      />
    </StyledDashboardHeader>
  );
};

export default SubHeader;

import React, { useEffect, useState, Fragment } from 'react';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';

const PreviewClozeWithText = ({
                                currentQuestion,
                                showAnswer = undefined,
                                setShowAnswers = undefined,
                                parentMode = 'assessment',
                                editMode = false,
                              }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const more_options = currentQuestion?.more_options;

  const [responses, setResponses] = useState({});
  const [elements, setElements] = useState([]);
  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const [answerStatus, setAnswerStatus] = useState({}); // Track correctness per response

  const handleResponseChange = (fieldId, value) => {
    setResponses({ ...responses, [fieldId]: value });
  };

  useEffect(() => {
    if (showAnswer || localShowAnswer) {
      if (Array.isArray(currentQuestion?.correct_answer)) {
        setElements(currentQuestion?.correct_answer);
      } else if (
          currentQuestion?.correct_answer &&
          currentQuestion?.correct_answer?.valid_response &&
          Array.isArray(currentQuestion?.correct_answer?.valid_response?.value)
      ) {
        setElements(currentQuestion?.correct_answer?.valid_response?.value);
      }
    } else {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
          currentQuestion?.template_response,
          'text/html'
      );
      const elements = Array.from(doc.body.children);
      let currentId = 0;

      const responses = elements.flatMap((element) => {
        const result = [];
        let position = 0;
        if (element.tagName === 'P') {
          const childNodes = Array.from(element.childNodes);
          childNodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
              const wordsArray = node.textContent.trim().split(/\s+|&nbsp;/);
              wordsArray.forEach((word) => {
                const cleanedWord =
                    word === '<p><br></p>'
                        ? '*'
                        : word === '<p>&nbsp;</p>'
                            ? '*'
                            : word;
                result.push({
                  type: 'txt',
                  content: cleanedWord,
                  id: currentId,
                  position: position++,
                });
                if (cleanedWord === 'Response') {
                  result.push({
                    type: 'Response',
                    content: cleanedWord,
                    id: currentId,
                    position: position++,
                  });
                }
              });
            } else if (
                node.nodeType === Node.ELEMENT_NODE &&
                (node as Element).tagName === 'IMG'
            ) {
              const imgElement = node as HTMLImageElement;
              result.push({
                type: 'img',
                content: imgElement.src,
                width: imgElement.width,
                height: imgElement.height,
                id: currentId,
                position: position++,
              });
            } else if (
                node.nodeType === Node.ELEMENT_NODE &&
                (node as Element).tagName === 'STRONG'
            ) {
              const wordsArray = node.textContent.trim().split(/\s+|&nbsp;/);
              wordsArray.forEach((word) => {
                const cleanedWord =
                    word === '<p><br></p>'
                        ? '*'
                        : word === '<p>&nbsp;</p>'
                            ? '*'
                            : word;
                if (cleanedWord === 'Response') {
                  result.push({
                    type: 'Response',
                    content: cleanedWord,
                    id: currentId,
                    position: position++,
                  });
                } else {
                  result.push({
                    type: 'txt',
                    content: cleanedWord,
                    id: currentId,
                    position: position++,
                  });
                }
              });
            }
          });
          currentId++;
        } else if (element.tagName === 'FIGURE') {
          Array.from(element.childNodes).forEach((node, index) => {
            if (
                node.nodeType === Node.ELEMENT_NODE &&
                (node as Element).tagName === 'TABLE'
            ) {
              result.push({
                type: 'table',
                content: (node as Element).outerHTML,
                id: currentId,
                position: position++,
              });
            } else if (
                node.nodeType === Node.ELEMENT_NODE &&
                (node as Element).tagName === 'IMG'
            ) {
              const imgElement = node as HTMLImageElement;
              let imgalignment = '';

              if (element.className.includes('image-style-side')) {
                imgalignment = 'right';
              } else if (element.className.includes('image')) {
                imgalignment = 'center';
              }

              result.push({
                type: 'img',
                content: imgElement.src,
                width: imgElement.width,
                height: imgElement.height,
                id: currentId,
                position: position++,
                alttext: imgElement.alt,
                imgalignment: imgalignment,
              });
            }
          });
          currentId++;
        }
        return result;
      });

      const modifiedFilledWords = responses.reduce((result, res) => {
        if (res.content === '') {
          result.push({
            type: 'txt',
            content: '\n',
            id: res.id,
            position: res.position,
          });
        } else {
          result.push(res);
        }
        return result;
      }, []);
      setElements(modifiedFilledWords);
    }
  }, [currentQuestion, showAnswer, localShowAnswer]);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(
        setTypeQuestionOpenInEditMode({
          type: currentQuestion?.type,
          id: currentQuestion?.id,
        })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleCheckAnswer = () => {
    // Format user responses array from the elements array and responses state
    const userResponsesArray = formatUserResponses(elements, responses);

    console.log('Formatted User Responses:', userResponsesArray);

    const correctAnswerArray = currentQuestion.correct_answer?.valid_response?.value || [];

    // Extract all "Response" type correct answers
    const filteredCorrectAnswers = correctAnswerArray.filter((answer) => answer.type === "Response");

    const status = {};

    // Iterate over user responses and compare with correct answers
    userResponsesArray.forEach((userResponse, index) => {
      if (userResponse.type === "Response") {
        // Find the corresponding correct answer by matching the `id` and `position`
        const correctAnswer = filteredCorrectAnswers.find(
            (answer) => answer.id === userResponse.id && answer.position === userResponse.position
        );

        if (correctAnswer) {
          let cleanedCorrectAnswer, cleanedUserResponse;
          if(currentQuestion?.more_options?.scoring?.case_sensitive){
             cleanedCorrectAnswer = String(correctAnswer.value).trim().toLowerCase();
             cleanedUserResponse = String(userResponse.content).trim().toLowerCase();
          } else {
            cleanedCorrectAnswer = correctAnswer.value;
            cleanedUserResponse = userResponse.content;
          }


          // Update status for the corresponding response field
          status[`response-${index}`] = cleanedCorrectAnswer === cleanedUserResponse;
        } else {
          status[`response-${index}`] = false; // No corresponding correct answer found
        }
      }
    });

    setAnswerStatus(status);
  };

  // Function to format user responses with id and position based on elements array
  const formatUserResponses = (elements, responses) => {
    return elements.map((element, index) => {
      if (element.type === "Response") {
        return {
          ...element,
          content: responses[`response-${index}`] || '', // Get the user's response or empty if none provided
        };
      }
      return element; // For non-response elements, return them unchanged
    });
  };

  return (
      <PreviewWrapper
          currentQuestion={currentQuestion}
          showAnswer={showAnswer ?? localShowAnswer}
          setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
          parentMode={parentMode}
          editMode={editMode}
          handleGoToEdit={handleGoToEditClick}
          handleBackToEdit={handleBackToEditClick}
      >   

        <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              border: '1px solid #D9D9D9',
              padding: '24px',
              marginBottom: '10px',
              backgroundColor: '#f0f0f0',
            }}
        >
          <div
              style={
                more_options && more_options?.layout.fontSize
                    ? { fontSize: more_options?.layout.fontSize, lineHeight: 'normal', width: '100%' }
                    : { width: '100%' }
              }
          >
            {elements.map((res, index) => (
                <Fragment key={index}>
                  {index > 0 && res.id === elements[index - 1].id && <span>&nbsp;</span>}
                  {index > 0 && res.id !== elements[index - 1].id && <br />}
                  {res.content === 'Response' ? (
                      <span key={index} className="align-center justify-center ml-1">
                  {showAnswer || localShowAnswer ? (
                      <span
                          className="underline font-bold"
                          style={handleResponseStyle(more_options, elements, index)}
                      >
                      {res.value}
                    </span>
                  ) : (
                      <InputWithButton
                          more_options={more_options}
                          index={index}
                          showAnswer={showAnswer}
                          localShowAnswer={localShowAnswer}
                          responses={responses}
                          res={res}
                          handleResponseChange={handleResponseChange}
                          elements={elements}
                          answerStatus={answerStatus}
                      />
                  )}
                </span>
                  ) : res.type === 'img' ? (
                      res.imgposition === '' ? (
                          <img
                              key={index}
                              src={res.content}
                              width={res.width}
                              height={res.height}
                              alt={res.content}
                          />
                      ) : (
                          <div style={{ textAlign: res.imgalignment }}>
                            <img
                                key={index}
                                src={res.content}
                                width={res.width}
                                height={res.height}
                                alt={res.alttext}
                                style={{ margin: 'auto', display: 'inline' }}
                            />
                          </div>
                      )
                  ) : res.type === 'table' ? (
                      <span
                          className="answertblclass"
                          dangerouslySetInnerHTML={{ __html: res.content }}
                      />
                  ) : (
                      <span>{res.content}</span>
                  )}
                </Fragment>
            ))}
            { currentQuestion?.more_options?.ui_style?.instant_feedback && (
                <div style={{ marginTop: '20px' }}>
                  <button
                      onClick={handleCheckAnswer}
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                  >
                    Check Answer
                  </button>
                </div>
            )}
          </div>
        </div>
      </PreviewWrapper>
  );
};

export default PreviewClozeWithText;

const InputWithButton = ({
                           more_options,
                           index,
                           showAnswer,
                           localShowAnswer,
                           responses,
                           res,
                           handleResponseChange,
                           elements,
                           answerStatus,
                         }) => {
  console.log(index);
  console.log(answerStatus);
  const borderColor =
      answerStatus[`response-${index}`] === undefined
          ? 'gray' // Default color if answer has not been checked yet
          : answerStatus[`response-${index}`]
              ? 'green' // If the answer is correct
              : 'red'; // If the answer is incorrect

  return (
      <div className="inline-block relative mx-6">
        <input
            type={
              more_options && more_options?.response_options?.input_type
                  ? more_options?.response_options?.input_type
                  : 'text'
            }
            placeholder={handleResProperty(more_options, elements, index, 'placeholder', '')}
            id={`response-${index}`}
            spellCheck={
                more_options &&
                more_options?.layout?.spellcheck &&
                more_options?.layout?.spellcheck
            }
            value={
              !showAnswer || !localShowAnswer
                  ? responses[`response-${index}`]
                      ? responses[`response-${index}`]
                      : ''
                  : res?.value || ''
            }
            onChange={(e) => handleResponseChange(`response-${index}`, e.target.value)}
            className="rounded px-3 py-2 mb-2"
            style={{
              border: `2px solid ${borderColor}`,
              ...handleResponseStyle(more_options, elements, index),
            }}
        />
      </div>
  );
};

const handleResponseStyle = (moreOptions, correctAnswers, index) => {
  let style = { width: 'fit-content', height: 'fit-content' };
  const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
  if (moreOptions && resOptionsIndividaul.length === 0) {
    style = {
      ...moreOptions?.response_options,
      width: moreOptions?.response_options?.width
          ? `${moreOptions.response_options.width}px`
          : 'fit-content',
      height: moreOptions?.response_options?.height
          ? `${moreOptions.response_options.height}px`
          : 'fit-content',
      fontSize: moreOptions?.layout?.fontSize
          ? `${moreOptions?.layout.fontSize}`
          : 'inherit',
      lineHeight: 'normal',
      minWidth: 'fit-content',
    };
  }
  if (moreOptions && resOptionsIndividaul.length > 0) {
    const allRes = correctAnswers.filter((ca) => ca?.type === 'Response');
    const currentResFromCorrectAnswers = correctAnswers[index];
    const currentResIndex = allRes.findIndex(
        (res) => currentResFromCorrectAnswers === res
    );
    if (currentResIndex > -1) {
      const currentResOptions = resOptionsIndividaul.find(
          (_item, idx) => idx === currentResIndex
      );
      if (currentResOptions) {
        style = {
          ...style,
          width: currentResOptions?.width
              ? `${currentResOptions?.width}px`
              : moreOptions?.response_options?.width
                  ? `${moreOptions.response_options.width}px`
                  : 'fit-content',
          height: currentResOptions?.height
              ? `${currentResOptions?.height}px`
              : moreOptions?.response_options?.height
                  ? `${moreOptions.response_options.height}px`
                  : 'fit-content',
        };
      }
    }
  }
  return style;
};

const handleResProperty = (moreOptions, correctAnswers, index, propName, defaultValue = "") => {
  let val =
      moreOptions && moreOptions?.response_options[`${propName}`]
          ? moreOptions?.response_options[`${propName}`]
          : defaultValue;

  const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
  if (moreOptions && resOptionsIndividaul.length > 0) {
    const allRes = correctAnswers.filter((ca) => ca?.type === 'Response');
    const currentResFromCorrectAnswers = correctAnswers[index];
    const currentResIndex = allRes.findIndex(
        (res) => currentResFromCorrectAnswers === res
    );
    if (currentResIndex > -1) {
      const currentResOptions = resOptionsIndividaul.find(
          (_item, idx) => idx === currentResIndex
      );
      if (currentResOptions) {
        val = currentResOptions[`${propName}`]
            ? currentResOptions[`${propName}`]
            : val;
      }
    }
  }

  return val;
};

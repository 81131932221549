import React from "react";

interface ButtonProps {
    name: string;
    onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ name, onClick }) => {
    return (
        <button
            style={{
                margin: 8,
                padding: "10px 16px",
                color: "white",
                backgroundImage: 'linear-gradient(rgb(250, 155, 49), rgb(227, 33, 39))',
                border: "none",
                borderRadius: ".4rem",
            }}
            onClick={(e)=>{
                e.preventDefault();
                onClick();
            }}
        >
            {name}
        </button>
    );
};

export default Button;

import React from 'react';
import styled from 'styled-components';
import { FontSize, StyledCellProps } from './types';
import { TableCell } from "./TableCell";

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const EmptyCell = styled.td`
  border: 1px solid #eee;
  background-color: #f8f9fa;
  height: 50px;
`;

interface GriddedTableProps {
    columns: number;
    range?: { min: number; max: number };
    options: {
        range?: { min: number; max: number };
        plusMinus?: boolean;
        fractionSlash?: boolean;
        fixedDecimal?: boolean;
        floatingDecimal?: boolean;
    };
    fontSize?: FontSize;
    onChange: (column: number, value: string) => void;
    disabled?: boolean;
    showPlusRow?: boolean;
    showMinusRow?: boolean;
    selectedValues?: string[];
}

export const GriddedTable: React.FC<GriddedTableProps> = ({
                                                              columns,
                                                              range,
                                                              options,
                                                              fontSize,
                                                              onChange,
                                                              disabled,
                                                              showMinusRow,
                                                              showPlusRow,
                                                              selectedValues = [],
                                                          }) => {

    const values = [
        //...(options?.plusMinus ? ['+', '-'] : []),
        ...(options?.fractionSlash ? ['/'] : []),
        ...(options?.fixedDecimal || options?.floatingDecimal ? ['.'] : []),
        ...Array.from({ length: 10 }, (_, i) => `${ i }`)
    ];

    console.log('*****************3333333ZZZZ', options, values, range)

    return (
        <Table>
            <tbody>
            {/* First row with + */}
            { options?.plusMinus &&
                <tr>
                    <TableCell
                        value="+"
                        columnIndex={0}
                        rowIndex={0}
                        fontSize={fontSize}
                        disabled={disabled}
                        onChange={(value) => onChange(0, value)}
                        selectedValue={selectedValues[0]}
                    />
                    {Array.from({ length: columns - 1 }).map((_, colIndex) => (
                        <TableCell
                            key={colIndex}
                            value="."
                            columnIndex={colIndex + 1}
                            rowIndex={0}
                            fontSize={fontSize}
                            disabled={disabled}
                            onChange={(value) => onChange(colIndex + 1, value)}
                            selectedValue={selectedValues[colIndex + 1]}
                        />
                    ))}
                </tr>
            }

            {/* Second row with - */}
            { options?.plusMinus &&
                <tr>
                    <TableCell
                        value="-"
                        columnIndex={0}
                        rowIndex={1}
                        fontSize={fontSize}
                        disabled={disabled}
                        onChange={(value) => onChange(0, value)}
                        selectedValue={selectedValues[0]}
                    />
                    {Array.from({ length: columns - 1 }).map((_, colIndex) => (
                        <TableCell
                            key={colIndex}
                            value="0"
                            columnIndex={colIndex + 1}
                            rowIndex={1}
                            fontSize={fontSize}
                            disabled={disabled}
                            onChange={(value) => onChange(colIndex + 1, value)}
                            selectedValue={selectedValues[colIndex + 1]}
                        />
                    ))}
                </tr>
            }
            {/* Number rows */}
            {values.map((num, rowIndex) => (
                <tr key={rowIndex}>
                    <EmptyCell />
                    {Array.from({ length: columns - 1 }).map((_, colIndex) => (
                        <TableCell
                            key={colIndex}
                            value={num}
                            columnIndex={colIndex + 1}
                            rowIndex={rowIndex + 2}
                            fontSize={fontSize}
                            disabled={disabled}
                            onChange={(value) => onChange(colIndex + 1, value)}
                            selectedValue={selectedValues[colIndex + 1]}
                        />
                    ))}
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

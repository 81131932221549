import { useMemo } from 'react';

export const useGridConfig = (question: any): any => {
    console.log(question)
    return useMemo(() => ({
        columns: question?.more_options?.layout?.columns || 6,
        fontSize: question?.more_options?.layout?.fontSize || 'medium',
        range: {
            min: question?.more_options?.layout?.range?.min || 0,
            max: question?.more_options?.layout?.range?.max || 9
        },
        features: {
            plusMinus: question?.more_options?.layout?.features?.plusMinus || false,
            fractionSlash: question?.more_options?.layout?.features?.fractionSlash || false,
            fixedDecimal: question?.more_options?.layout?.features?.fixedDecimal || false,
            floatingDecimal: question?.more_options?.layout?.features?.floatingDecimal || false
        },
        ...question
    }), [question]);
};

import Button from "components/Button";
import { AiOutlinePlus } from "react-icons/ai";

const AddButtonForCharts = ({handleAddNewPoint} : {handleAddNewPoint: (e: any) => void}) => {
    return(
      <div className="mb-4">
        <Button
          margin
          icon={<AiOutlinePlus />}
          title={'Add data'}
          onClick={handleAddNewPoint}
          // className='flex items-center gap-2 justify-center'
          // style={{backgroundColor: 'gray', color: 'white', borderColor: 'white'}}
        />
      </div>
    )
};

export default AddButtonForCharts;
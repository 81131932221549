import React, { useId } from 'react';
import {FontSize, StyledCellProps} from './types';
import styled from "styled-components";

const Cell = styled.td<StyledCellProps>`
  border: 1px solid #eee;
  padding: 8px;
  text-align: center;
  height: 50px;
  position: relative;
  background-color: #dee2e6;
  font-size: ${props =>
    props.$fontSize === 'small' ? '14px' :
        props.$fontSize === 'large' ? '20px' : '16px'
};
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const RadioLabel = styled.label<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border-radius: 50%;
  transition: all 0.2s ease;
  position: relative;
  background-color: ${props => props.$isSelected ? '#4cd964' : 'white'};
  color: ${props => props.$isSelected ? 'white' : 'inherit'};
  
  &:hover {
    background-color: ${props => props.$isSelected ? '#4cd964' : 'rgba(76, 217, 100, 0.1)'};
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
interface TableCellProps {
    value: string;
    columnIndex: number;
    rowIndex: number;
    fontSize: FontSize;
    disabled?: boolean;
    onChange: (value: string) => void;
    selectedValue?: string;
}

export const TableCell: React.FC<TableCellProps> = ({
                                                        value,
                                                        columnIndex,
                                                        rowIndex,
                                                        fontSize,
                                                        disabled,
                                                        onChange,
                                                        selectedValue,
                                                    }) => {
    const randomId = useId();
    const isSelected = selectedValue === value;
    const inputId = `radio-${rowIndex}-${columnIndex}-${value}-${randomId}`;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            onChange(value);
        } else {
            onChange('');
        }
    };

    return (
        <Cell $fontSize={fontSize} data-lrn-gridded-column-wrapper={columnIndex}>
            <RadioInput
                type="radio"
                id={inputId}
                name={`gridded-group-${columnIndex}-${randomId}`}
                checked={isSelected}
                onChange={handleChange}
                disabled={disabled}
                value={value}
            />
            <RadioLabel htmlFor={inputId} $isSelected={isSelected}>
                {value}
            </RadioLabel>
        </Cell>
    );
};

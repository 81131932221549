import {GridQuestion, ImageAnnotationData} from '../types';

export const handleImageUploadQuestion = (
    state: any,
    key: string,
    subKey: string,
    value: any
): void => {
    // Always initialize the base structure for the key if it doesn't exist

    state.subQuestions[key].type = key;

    if (!state.subQuestions[key]) {
        state.subQuestions[key] = {
            type: key,
            question: "",
            image: {},
            correct_answer: {
                valid_response: {
                    score: 0,
                    value: [],
                },
            },
            more_options: {},
        } as ImageAnnotationData;
    }

    // Handle specific subKey updates
    if (['textHover', 'width', 'alt', 'source', 'height'].includes(subKey)) {
        // Update image properties
        state.subQuestions[key].image = {
            ...state.subQuestions[key].image,
            [subKey]: value,
        };
        return;
    }

    if (['points', 'correct_answer'].includes(subKey)) {
        // Ensure correct_answer structure exists
        if (!state.subQuestions[key].correct_answer) {
            state.subQuestions[key].correct_answer = {
                valid_response: { score: 0, value: [] }
            };
        }

        if (!state.subQuestions[key].correct_answer.valid_response) {
            state.subQuestions[key].correct_answer.valid_response = {
                score: 0,
                value: []
            };
        }

        // Update scoring or correct answer values
        if (subKey === 'points') {
            state.subQuestions[key].correct_answer.valid_response.score = value;
        } else {
            state.subQuestions[key].correct_answer.valid_response.value = value;
        }
        return;
    }

    if (subKey === 'more_options') {
        // Update more_options
        state.subQuestions[key].more_options = {
            ...state.subQuestions[key].more_options,
            ...value,
        };
        return;
    }

    if (subKey === '') {
        // Directly update the entire key's properties
        state.subQuestions[key] = {
            ...state.subQuestions[key],
            ...value,
        };
        return;
    }
    else {
        state.subQuestions[key][subKey] = value;
    }
    // General case for other subKeys
    //state.subQuestions[key][subKey] = value;
};

export const handleGridQuestion = (state: any, key: string, subKey: string, value: any): void => {
    // Initialize grid question if it doesn't exist

    state.subQuestions[key].type = key;

    if (!state.subQuestions[key]) {
        state.subQuestions[key] = {
            type: 'grid',
            correct_answer: {
                valid_response: { value: [], score: 0 },
                alt_responses: []
            }
        };
    }

    else {
        state.subQuestions[key][subKey] = value;
    }
};

import React from 'react';
import styled from 'styled-components';
import { GriddedResponse, GriddedValue } from '../GriddedResponse';
import { defaultGriddedConfig } from '../MoreOptions/config';

const TabContentContainer = styled.div`
  border: 1px solid #dadada;
  padding: 16px;
  background: white;
  width: fit-content;
`;

const PointsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 55px;
  padding: 8px;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 300px;
  font-size: small;
  margin-top: 16px;
`;

const PointsInput = styled.input`
  outline: none;
  width: fit-content;
  padding: 10px;
  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

interface AnswerTabContentProps {
    value: string[];
    score: number;
    onValueChange: (value: string[]) => void;
    onScoreChange: (score: number) => void;
    alternativeId?: string;
    disabled?: boolean;
    isPreview?: boolean;
    hidePoints?: boolean;
}

const AnswerTabContent: React.FC<AnswerTabContentProps> = ({
                                                               value,
                                                               score,
                                                               onValueChange,
                                                               onScoreChange,
                                                               alternativeId,
                                                               disabled = false,
                                                               isPreview = false,
                                                               hidePoints = false
                                                           }) => {
    console.log('hidePoints prop:', hidePoints);  // Log here to check the value of hidePoints

    const griddedValue: GriddedValue = {
        values: value || Array(defaultGriddedConfig.columns).fill('')
    };


    const handleGriddedChange = (newValue: GriddedValue) => {
        onValueChange(newValue.values);
    };

    return (
        <>
            <TabContentContainer>
                <GriddedResponse
                    config={defaultGriddedConfig}
                    value={griddedValue}
                    onChange={handleGriddedChange}
                    disabled={disabled}
                />
            </TabContentContainer>
            {!isPreview && !hidePoints && (
                <PointsContainer>
                    <span>Points</span>
                    <PointsInput
                        type="number"
                        min="0"
                        value={score}
                        onChange={(e) => onScoreChange(Number(e.target.value))}
                        disabled={disabled}
                    />
                </PointsContainer>
            )}
        </>
    );
};

export default AnswerTabContent;

import { useEffect, useMemo, useState } from "react";
import { QuestionContent } from "./styled";
import Button from "../../../components/Button";
import { setClosePreview } from "../../../redux/slices/PreviewSlice";
import { useNavigate } from "react-router-dom";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { AiOutlineInfoCircle } from "react-icons/ai";

// Tooltip Component
const Tooltip: React.FC<{ content: React.ReactNode }> = ({ content }) => (
  <div className="absolute bottom-full left-0 mb-2 hidden group-hover:block bg-white shadow-lg rounded p-2 text-sm z-10 max-w-xs">
    {content}
  </div>
);

// Strip HTML tags
const stripHTML = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

// Info Tooltip Component
const InfoTooltip: React.FC<{ ratingOptions: any[] }> = ({ ratingOptions }) => (
  <div className="absolute left-0 mt-2 bg-white shadow-lg rounded p-4 text-sm z-10 border border-gray-300" style={{ width: "max-content" , marginTop: "23px"}}>
    {ratingOptions.map((option, index) => (
      <div key={index} className="mb-3">
        <p style={{ color: option.color, fontWeight: "bold" }}>
          {option.label} - {stripHTML(option.tooltip)}
        </p>
        <p>{stripHTML(option.description)}</p>
      </div>
    ))}
  </div>
);


// Main Component
const PreviewRating = ({
  currentQuestion,
  showAnswer = false,
  setShowAnswers = null,
  parentMode = "assessment",
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false); 
  const moreOptions = currentQuestion?.more_options || {}; 
  const hideInfoIcon = moreOptions.ui_style?.hide_info_icon; 
  // console.log("hideInfoIcon",currentQuestion)
  const ratingOptions = Array.isArray(currentQuestion?.options) ? currentQuestion.options : [];

  const initialResponse = useMemo(() => ({ value: null, score: 0 }), []);

  const [userResponse, setUserResponse] = useState(initialResponse);

  useEffect(() => {
    if (!userResponse.value) {
      setUserResponse(initialResponse); 
    }
  }, []);

  const handleSelectRating = (value: string) => {
    setUserResponse({ ...userResponse, value });
  };

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode({ type: "rating", id: currentQuestion?.id }));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  return (
    <QuestionContent parentMode={parentMode === "question"} style={moreOptions && moreOptions?.ui_style.fontSize ? 
      {fontSize: moreOptions.ui_style.fontSize}: 
      {fontSize: 'medium'}
    }>
       {
        moreOptions?.metadata?.instructor_stimulus &&
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {moreOptions?.metadata?.instructor_stimulus}
        </div>
      }
      <div className="flex flex-wrap gap-5 justify-between mb-5">
        <div className="w-full lg:w-[70%]">
          <div className="bg-light-accent flex p-5 gap-3 rounded-lg"  >
            <p className="font-bold text-sm" style={moreOptions && moreOptions?.ui_style.fontSize ? 
              {fontSize: moreOptions.ui_style.fontSize}: {fontSize: 'medium'} }>Question:&nbsp;</p>
            <span>{stripHTML(currentQuestion?.question || "")}</span>
          </div>
        </div>

        <div className="flex gap-4">
          {editMode ? (
            <>
              <Button margin title={"Go to edit"} onClick={handleEditClick} />
              <Button
                title={"Delete"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />
            </>
          ) : (
            <>
             
              <Button
                title={"Back to edit"}
                onClick={() => {
                  dispatch(setClosePreview());
                }}
              />
            </>
          )}
        </div>
      </div>

      {/* Align Info Icon and Rating Buttons */}
      <div className="flex items-center gap-4">
        {/* Info Icon */}
        {!hideInfoIcon && (
          <div className="relative group">
          <button
            className="flex items-center gap-2 text-gray-600 hover:text-blue-600 focus:outline-none"
            onClick={() => setInfoOpen(!infoOpen)}
          >
            <AiOutlineInfoCircle size={20} />
            <span>Rating</span>
          </button>
          {infoOpen && <InfoTooltip ratingOptions={ratingOptions} />}
        </div>
        )}
       

        {/* Rating Buttons */}
        <div className="flex gap-2">
          {ratingOptions.map((option) => (
            <div key={option.id} className="relative group">
              <button
                style={{ width: "210px" }}
                className={`p-4 rounded border ${
                  userResponse?.value === option.value
                    ? "border-blue-500 bg-blue-100"
                    : "border-gray-300 bg-white"
                }`}
                onClick={() => handleSelectRating(option.value)}
              >
                {option.label}
              </button>
              <Tooltip
                content={
                  <div>
                    <p style={{ color: option.color, fontWeight: "bold" }}>
                      {stripHTML(option.tooltip)}
                    </p>
                  </div>
                }
              />
            </div>
          ))}
        </div>
      </div>
    </QuestionContent>
  );
};

export default PreviewRating;

import styled from "styled-components";

export const CanvasWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

export const CanvasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 768px) {
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

export const LabelInputWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 600px;

  > div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      font-weight: bold;
      min-width: 150px;
    }

    input {
      flex: 1;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      transition: all 0.2s ease;
    }
  }
`;

export const ResponseInputWrapper = styled(LabelInputWrapper)`
  > div {
    input {
      border-color: #ddd;

      &:focus {
        outline: none;
        border-color: #4299e1;
        box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
      }

      &.selected {
        border-color: #4299e1;
        background-color: rgba(66, 153, 225, 0.05);
      }
    }
  }
`;
